<template>
  <section class="footer-section">
  </section>
</template>

<script>
  export default {
    name: 'FooterBlock'
  };
</script>
